/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h3: "h3",
    div: "div",
    ul: "ul",
    li: "li",
    h4: "h4"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://github.com/golang/dep"
  }, "dep"), "とは、golangのpackageをローカルで管理するためのツール"), "\n", React.createElement(_components.p, null, "Gopkg.lockに書かれたpackageのリビジョンを変更する方法を試したので残しておく"), "\n", React.createElement(_components.h3, {
    id: "環境",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E7%92%B0%E5%A2%83",
    "aria-label": "環境 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "環境"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "go: 1.9.3"), "\n", React.createElement(_components.li, null, "dep: v0.4.1"), "\n"), "\n", React.createElement(_components.h3, {
    id: "概要",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E6%A6%82%E8%A6%81",
    "aria-label": "概要 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "概要"), "\n", React.createElement(_components.p, null, "Gopkg.lockファイルに依存関係にある各packageとリビジョンが以下のように書かれている"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "\n        <div class=\"undefined\">\n          <span>Gopkg.lock</span>\n        </div>\n       "
    }
  }), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"toml\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-toml line-numbers\"><code class=\"language-toml\"><span class=\"token punctuation\">[</span><span class=\"token punctuation\">[</span><span class=\"token table class-name\">projects</span><span class=\"token punctuation\">]</span><span class=\"token punctuation\">]</span>\n  <span class=\"token key property\">name</span> <span class=\"token punctuation\">=</span> <span class=\"token string\">\"github.com/xxxx/xxxx\"</span>\n  <span class=\"token key property\">packages</span> <span class=\"token punctuation\">=</span> <span class=\"token punctuation\">[</span><span class=\"token string\">\".\"</span><span class=\"token punctuation\">]</span>\n  <span class=\"token key property\">revision</span> <span class=\"token punctuation\">=</span> <span class=\"token string\">\"f587xxxxxxxxxxxxx\"</span>\n  <span class=\"token key property\">version</span> <span class=\"token punctuation\">=</span> <span class=\"token string\">\"v1.2.0\"</span></code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "この状態から、branch = “master”のバージョンに変える方法を書いていく"), "\n", React.createElement(_components.h4, {
    id: "1-gopkglockを編集",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#1-gopkglock%E3%82%92%E7%B7%A8%E9%9B%86",
    "aria-label": "1 gopkglockを編集 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "1. Gopkg.lockを編集"), "\n", React.createElement(_components.p, null, "(直接編集するのはアンチパターンのようですが)Gopkg.lockを編集する\n下のように、versionを削除し、branchとrevisionを追加する\nrevisionにはcommit idを設定する"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "\n        <div class=\"undefined\">\n          <span>Gopkg.lock</span>\n        </div>\n       "
    }
  }), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"toml\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-toml line-numbers\"><code class=\"language-toml\"><span class=\"token punctuation\">[</span><span class=\"token punctuation\">[</span><span class=\"token table class-name\">projects</span><span class=\"token punctuation\">]</span><span class=\"token punctuation\">]</span>\n  <span class=\"token key property\">branch</span> <span class=\"token punctuation\">=</span> <span class=\"token string\">\"master\"</span>\n  <span class=\"token key property\">name</span> <span class=\"token punctuation\">=</span> <span class=\"token string\">\"github.com/satori/go.uuid\"</span>\n  <span class=\"token key property\">packages</span> <span class=\"token punctuation\">=</span> <span class=\"token punctuation\">[</span><span class=\"token string\">\".\"</span><span class=\"token punctuation\">]</span>\n  <span class=\"token key property\">revision</span> <span class=\"token punctuation\">=</span> <span class=\"token string\">\"36e9xxxxxxxxxxxxxxxxxxxxx\"</span></code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 600px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/d8f6dc9cd2f57e5d56d2b45ba116657f/497e9/uuid-commit-log.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 18.666666666666668%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA40lEQVQY012Py0rDUABE86niLgmCPyFFECUgFMS6V7H1GxQsuG66qA9EaWoVDLn3prnPHGmQCh04DDOzmoi6ZFtSKppGU1UCqWq01ggh0MZircM5h7V2wzp3nXNEdw+PXF5dczMcdQxHt8y/flg5qFYW0TikCX/edqw3wz/Kg3RgA0S97IyDo4zDrE/v+JST/oBZUfKt4VO1LGtYqEAhPHPhWcjAe6mZvi3JXwry14Lx5IP7SUGpDNH54KK72W7d9j7gQyCEFmMsQioqqXDO85zn7CcJcRyTpil7aUK6u8PTNOcX4kwiSoZYsc8AAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"uuid commit log\"\n        title=\"uuid commit log\"\n        src=\"/static/d8f6dc9cd2f57e5d56d2b45ba116657f/0a47e/uuid-commit-log.png\"\n        srcset=\"/static/d8f6dc9cd2f57e5d56d2b45ba116657f/8a4e8/uuid-commit-log.png 150w,\n/static/d8f6dc9cd2f57e5d56d2b45ba116657f/5a46d/uuid-commit-log.png 300w,\n/static/d8f6dc9cd2f57e5d56d2b45ba116657f/0a47e/uuid-commit-log.png 600w,\n/static/d8f6dc9cd2f57e5d56d2b45ba116657f/1cfc2/uuid-commit-log.png 900w,\n/static/d8f6dc9cd2f57e5d56d2b45ba116657f/c1b63/uuid-commit-log.png 1200w,\n/static/d8f6dc9cd2f57e5d56d2b45ba116657f/497e9/uuid-commit-log.png 2064w\"\n        sizes=\"(max-width: 600px) 100vw, 600px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  })), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\">dep ensure</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "で指定したバージョン、リビジョンのパッケージがvenderディレクトリ以下にダウンロードされる"), "\n", React.createElement(_components.h4, {
    id: "2-depコマンドで指定する",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#2-dep%E3%82%B3%E3%83%9E%E3%83%B3%E3%83%89%E3%81%A7%E6%8C%87%E5%AE%9A%E3%81%99%E3%82%8B",
    "aria-label": "2 depコマンドで指定する permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "2. depコマンドで指定する"), "\n", React.createElement(_components.p, null, "depコマンドでバージョンを指定して、追加してやる"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\">dep ensure <span class=\"token parameter variable\">-add</span> github.com/xxxx/xxxx@master</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "実行すると、指定したバージョンのパッケージがvenderディレクトリ以下にダウンロードされ\n各tomlファイルにバージョン情報が追記&編集される"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "\n        <div class=\"undefined\">\n          <span>Gopkg.toml</span>\n        </div>\n       "
    }
  }), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"toml\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-toml line-numbers\"><code class=\"language-toml\"><span class=\"token punctuation\">[</span><span class=\"token punctuation\">[</span><span class=\"token table class-name\">constraint</span><span class=\"token punctuation\">]</span><span class=\"token punctuation\">]</span>\n  <span class=\"token key property\">branch</span> <span class=\"token punctuation\">=</span> <span class=\"token string\">\"master\"</span>\n  <span class=\"token key property\">name</span> <span class=\"token punctuation\">=</span> <span class=\"token string\">\"github.com/xxxx/xxxx\"</span></code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "\n        <div class=\"undefined\">\n          <span>Gopkg.lock</span>\n        </div>\n       "
    }
  }), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"toml\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-toml line-numbers\"><code class=\"language-toml\"><span class=\"token punctuation\">[</span><span class=\"token punctuation\">[</span><span class=\"token table class-name\">projects</span><span class=\"token punctuation\">]</span><span class=\"token punctuation\">]</span>\n  <span class=\"token key property\">branch</span> <span class=\"token punctuation\">=</span> <span class=\"token string\">\"master\"</span>\n  <span class=\"token key property\">name</span> <span class=\"token punctuation\">=</span> <span class=\"token string\">\"github.com/xxxx/xxxx\"</span>\n  <span class=\"token key property\">packages</span> <span class=\"token punctuation\">=</span> <span class=\"token punctuation\">[</span><span class=\"token string\">\".\"</span><span class=\"token punctuation\">]</span>\n  <span class=\"token key property\">revision</span> <span class=\"token punctuation\">=</span> <span class=\"token string\">\"36e9xxxxxxxxxxxxxxxxxxxxx\"</span></code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "しかし、どちらも方法も"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\">dep ensure <span class=\"token parameter variable\">--update</span></code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "すると", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">Gopkg.lock</code>"
    }
  }), "の中身が戻ってしまうので注意"), "\n", React.createElement(_components.h4, {
    id: "参考",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E5%8F%82%E8%80%83",
    "aria-label": "参考 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "参考"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://qiita.com/Azizjan/items/66564b5dc7597717932b"
  }, "依存関係管理ツールdep(golang)")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://golang.github.io/dep/docs/Gopkg.lock.html"
  }, "Gopkg.lock · dep")), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
